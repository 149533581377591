<template>
    <footer>
        <div class="container-fluid">
            <div class="footer">
                <div class="footer-image">
                    <img src="/assets/footer.png" alt="Logo der Stadt Wolfsburg">
                </div>
                <ul class="footer-info">
                    <li><a href="/impressum" title=" Zu dem Impressum">Impressum</a></li>
                    <li><a href="/datenschutz" title=" Zu der Datenschutzerklärung">Datenschutz</a></li>
                    <li><a class="link d-none d-lg-flex" href="#" title="Kontaktieren Sie uns" tabindex="0" @click="showFeedbackModal">Kontakt</a></li>
                    <li class="copyright">© {{new Date().getFullYear()}} Bildungsbüro Wolfsburg</li>
                </ul>
            </div>
            <a href="https://cms.wolfsburgerlupe.de" class="btn btn-block link login" target="_blank" title="Login für Anbietende">LOGIN Für Anbietende</a>
        </div>
    </footer>
</template>

<script>
    import { bus } from '@/main';

    export default {
        name: 'Footer',
        methods: {
          showFeedbackModal() {
            bus.$emit('show-feedback-modal', true);
        }
    }
}
</script>
<style lang="scss" scoped>

    footer{

        @media (max-width: 991px) {
            display: none;
        }

        a,.link{
            color: #fff;
            cursor: pointer;

            &:hover{
                color: #C8D400;
                text-decoration: none;
            }
            &:focus-visible {
                color: #C8D400;
                text-decoration: none;
                border: none !important;
            }
        }
        
        .login {
         max-width: 252px;

         &:focus-visible {
            border: 2px dashed #21B5EA !important;
        }

    }
}
</style>